<template>
  <!-- 智慧楼宇 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧楼宇</div>
      <div class="title-p">
        智能楼宇系统集成建设与管理，以楼宇集成技术结合物联网及云技术，提供一个涵盖监视、控制、管理一体化的综合信息集成管理平台，为建筑能效优化和物业管理营运提供一站式解决方案。
      </div>
    </div>
    <div class="profiles-box">
      <div class="profiles-title">
        <div class="text">智慧楼宇分类</div>
      </div>
      <div class="profiles-content">
        <div class="item">
          <div class="item-title">智慧写字楼</div>
          <div class="item-content">
            <p>独立办公楼，可租赁给各个公司</p>
          </div>
        </div>
        <div class="item">
          <div class="item-title">智慧园区</div>
          <div class="item-content">
            <p>由多个写字楼组成园区，</p>
            <p>且有停车场、餐饮等配套设施</p>
          </div>
        </div>
        <div class="item">
          <div class="item-title">智慧联合办公空间</div>
          <div class="item-content">
            <p>服务于创业型企业，</p>
            <p>企业间共用会议室，</p>
            <p>打印机等公共设施与空间</p>
          </div>
        </div>
        <div class="item">
          <div class="item-title">智慧酒店</div>
          <div class="item-content">
            <p>提高城市治理能力现代化水平，</p>
            <p>实现城市可持续发展。</p>
          </div>
        </div>
        <div class="item">
          <div class="item-title">智慧政企</div>
          <div class="item-content">
            <p>政府企业等现代化智能管理办公大楼</p>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">平台架构</div>
      <img
        src="../../assets/image/buildingImg/组 177.png"
        alt=""
        width="100%"
      />
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
      <!-- <div class="box1">
        <div class="left">
          <div class="left-title">数据采集</div>
          <div class="left-text">
            现场采集内容覆盖楼宇自控、门禁、智能空调、ups、电梯、
            变配电、消防等系统。其中主要关注核心系统运行状况、主要
            能耗管网状态、环境介质质量监测等数据。将全楼宇的智能控
            制系统的实时状态采集进入系统，供数据监视、存储、报警、
            分析、计算、统计平衡等使用。
          </div>
        </div>
        <div class="right"></div>
      </div>
      <div class="box2">
        <div class="left"></div>
        <div class="right">
          <div class="right-title">能耗分析</div>
          <div class="right-text">
            通过能源消费结构，楼层能耗对比，重点耗能设备分析等多种分析方式，报表分析可以帮助物业管理人员计算特定房间或人均能耗，实现自主能源审计管理。报表可以自动生成，按实际需要实现手动或自动打印，供调度和运行管理人员使用。
          </div>
        </div>
      </div> -->
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">数据采集</div>
          <div class="sceneContent">
            现场采集内容覆盖楼宇自控、门禁、智能空调、ups、电梯、变配电、消防等系统。其中主要关注核心系统运行状况、主要能耗管网状态、环境介质质量监测等数据。将全楼宇的智能控制系统的实时状态采集进入系统，供数据监视、存储、报警、分析、计算、统计平衡等使用。
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">能耗分析</div>
          <div class="sceneContent">
            通过能源消费结构，楼层能耗对比，重点耗能设备分析等多种分析方式，报表分析可以帮助物业管理人员计算特定房间或人均能耗，实现自主能源审计管理。报表可以自动生成，按实际需要实现手动或自动打印，供调度和运行管理人员使用。
          </div>
        </div>
      </div>
      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">报警管理</div>
          <div class="sceneContent">
            <span>设备报警</span>
            <span>环境质量报警</span>
            <span>电源故障报警</span>
            <span>网络故障报警</span>
            <span>报警级别设定</span>
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">设备管理</div>
          <div class="sceneContent">
            能源管理系统的对象覆盖楼宇的各种大型能源设施，通过对能源设备的运行、异常、故障和事故状态实时监视和记录。通过技改和加强维护，指导维护保养工作，提高能源设备效率，实现能源设备闭环管理。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    go(i) {
      if (i == 1) {
        this.$router.push("data");
      }
      if (i == 2) {
        this.$router.push("energyConsumption");
      }
      if (i == 3) {
        this.$router.push("callPolice");
      }
      if (i == 4) {
        this.$router.push("equipment");
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/buildingImg/bg.png") no-repeat;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .profiles-box {
    .profiles-title {
      width: 100%;
      height: 80px;
      background: #3651d8;
      .text {
        font-size: 28px;
        padding: 20px 160px;
        font-weight: normal;
        color: #ffffff;
      }
    }
    .profiles-content {
      height: 230px;
      display: flex;
      padding: 0 10%;
      background: #ffffff;
      .item {
        width: 25%;
        text-align: center;
        .item-title {
          font-size: 28px;
          padding: 30px 0;
          font-weight: normal;
          color: #1864e3;
        }
        .item-content {
          font-size: 16px;
          font-weight: normal;
          color: #6b6b6b;
        }
      }
    }
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }
    .box1 {
      width: 100%;
      height: 400px;
      display: flex;
      background-color: #fff;
      .left {
        width: 50%;
        padding: 5% 10%;
        box-sizing: border-box;
        .left-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .left-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        background: url("../../assets/image/buildingImg/img1.png") no-repeat;
      }
    }
    .box2 {
      width: 100%;
      height: 400px;
      display: flex;
      margin-top: 20px;
      background-color: #fff;
      .left {
        width: 50%;
        height: 100%;
        background: url("../../assets/image/buildingImg/img2.png") no-repeat;
      }
      .right {
        width: 50%;
        padding: 7%;
        box-sizing: border-box;
        .right-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .right-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
    }
    .function-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .function-item {
        width: 18.5%;
        height: 260px;
        // margin-right: 2%;
        background-color: #fff;
        img {
          width: 100px;
          height: 100px;
          margin: 30px 0 0 0;
        }
        div {
          font-size: 20px;
          font-weight: 600;
          margin: 22px 0;
          color: #333333;
        }
        p {
          width: 80%;
          margin: auto;
          font-size: 14px;
          font-weight: normal;
          color: #888888;
        }
      }
    }
    .device {
      width: 100%;
      height: 400px;
      padding: 10%;
      box-sizing: border-box;
      background: url("../../assets/image/buildingImg/bottom.png") no-repeat;
      .device-text {
        font-size: 28px;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }
}
.scene {
  padding: 0 10%;
  display: flex;
  margin-bottom: 50px;
  overflow: hidden;
  > div {
    flex: 1;
    &:hover {
      transform: scale(1.07, 1.07);
    }
  }
  .sceneBox {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.7);
      opacity: 0.8;
      .sceneIcon {
        animation: moves 0.5s linear;
        animation-fill-mode: forwards;
      }

      .sceneContent {
        display: block;
      }
      @keyframes moves {
        0% {
        }
        100% {
          margin-top: 100px;
        }
      }
    }
    // flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 110px;
    box-sizing: border-box;
    .sceneIcon {
      margin-top: 180px;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
      box-sizing: border-box;
      padding: 0 15px;
      > span {
        display: inline-block;
        width: 46%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: rgba(168, 168, 168, 0.5);
        border-radius: 0px;
        margin: 15px 2% 0 0;
        color: #fff;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
      }
    }
  }
}
.img2 {
  background: url("../../assets/image/buildingImg/组 304.png") no-repeat;
  background-size: 100% 100%;
}
.img3 {
  background: url("../../assets/image/buildingImg/组 302.png") no-repeat;
  background-size: 100% 100%;
}
.img4 {
  background: url("../../assets/image/buildingImg/组 303.png") no-repeat;
  background-size: 100% 100%;
}
.img5 {
  background: url("../../assets/image/buildingImg/蒙版组 93.png") no-repeat;
  background-size: 100% 100%;
}
.icon1 {
  background: url("../../assets/image/buildingImg/组 157.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/buildingImg/组 161.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/buildingImg/组 162.png") no-repeat;
  background-size: 100% 100%;
}
.icon4 {
  background: url("../../assets/image/buildingImg/组 164.png") no-repeat;
  background-size: 100% 100%;
}
</style>
